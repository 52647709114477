/**
 * Manages the display of messages in a container element.  Messages can be permanent or with a timeout.
 *
 * @param root Selector for the element that will contains the messages.
 * @constructor
 */
var Messages = function(root) {

    /** Element containing the messages */
    this.root = $(root);

    /** This instance */
    var self = this;

    /** Default fade time for the display of the messages */
    this.fadeTime = 500;

    /** Template for an error message */
    this.error_template   = '<div class="message error"></div>';

    /** Template for a notice message */
    this.notice_template  = '<div class="message notice"></div>';

    /** Template for a success message */
    this.success_template = '<div class="message success"></div>';

    /** Template for the close button */
    this.close_template = '<a href="#" class="close" style="float:right;">&times;</a>';

    /**
     * Prepare an element to be displayed
     *
     * @param elem The message element
     * @param timeout Time the message must be displayed.  0 to disable the automatic fadeout
     * @return {*} The element
     */
    var prepare_element = function(elem, timeout)
    {
        var $elem = $(elem);

        if(timeout !== undefined && timeout > 0)
        {
            setTimeout(function() { $elem.trigger('remove'); }, timeout);
        }
        else
        {
            $elem.append($(self.close_template));
        }

        return $elem;
    };

    /**
     * Display an error message
     *
     * @param message The message to display
     * @param timeout Time the message must be displayed.  0 to disable the automatic fadeout
     */
    this.error = function(message, timeout)
    {
        prepare_element($(this.error_template).append(message), timeout).hide()
            .appendTo(this.root).fadeIn(self.fadeTime);
    };

    /**
     * Display a notice message
     *
     * @param message The message to display
     * @param timeout Time the message must be displayed.  0 to disable the automatic fadeout
     */
    this.notice = function(message, timeout)
    {
        prepare_element($(this.notice_template).append(message), timeout).hide()
            .appendTo(this.root).fadeIn(self.fadeTime);
    };

    /**
     * Display a success message
     *
     * @param message The message to display
     * @param timeout Time the message must be displayed.  0 to disable the automatic fadeout
     */
    this.success = function(message, timeout)
    {
        prepare_element($(this.success_template).append(message), timeout).hide()
            .appendTo(this.root).fadeIn(self.fadeTime);
    };

    // On click of the close buttons, trigger the remove event on the message element
    $(this.root).delegate('.close', 'click.message', function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        $(this).parent().trigger('remove');
    });

    // Remove handler
    $(this.root).delegate('.message.error, .message.notice, .message.success', 'remove.message', function()
    {
        $(this).fadeOut(self.fadeTime, function() { $(this).remove(); });
    });

    // Initialize the messages already present in the root container
    $(this.root).find('.message.error, .message.notice, .message.success').each(function()
    {
        var timeout = $(this).data('timeout');
        prepare_element(this, timeout);
    });

    /**
     * Display a temporary message inside the given container
     * @param {string} content
     * @param {string} extraClass
     * @param {int|int=} timeout
     * @param {object|object=} container
     */
    this.floatingMessage = function(content, extraClass, timeout, container)
    {
        container = container || $('body');
        //Sets default value without breaking existing calls to the method
        timeout = typeof timeout !== 'undefined' ? timeout : 1000;

        var $box_html = "<div style='display:none;' class='floatingMessage " + extraClass + "'>" + content + "</div>";
        container.prepend($box_html);

        var $obj = $('.floatingMessage');
        $obj.show('fast');
        setTimeout( function () {
            $obj.hide('slow', function () { $obj.remove(); });
        }, timeout);
    };

    return this;
};